.radio-button-group {
  display: flex;
  gap: 20px;
  justify-content: center;

  .radio-label {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 8px 12px;
    // border: 1px solid #007bff;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;

    &.active {
      // background-color: #2398bb;
      color: #060606;
    }

    // input[type="radio"] {
    //   display: none;
    // }
  }
}
