.alert-message-manager {
  .messages-list {
    .card {
      transition: all 0.3s ease;
      
      &:hover {
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      }
    }
  }

  .btn-group {
    .btn {
      &:not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }

  textarea {
    resize: vertical;
    min-height: 100px;
  }

  .spinner-border {
    width: 1rem;
    height: 1rem;
  }
}