body, html {
  overflow-x: hidden; // ป้องกันไม่ให้มีการเลื่อนหน้าจอในแนวตั้งและแนวนอน
  height: 100%;
}

#root {
  height: 100%; // ทำให้ root มีความสูงเต็ม 100% ของ viewport
}
.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #d3e1ee;
  // background-image: url('../../public/flow_background.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  img {
    width: 70%; // Larger default for small screens
    max-width: 220px; // General max size for most screens
    height: auto;
    margin-bottom: 20px;
  }

  form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 2px solid #add8e6;
    border-radius: 8px;
    width: 90%;
    max-width: 450px;
    text-align: center;
    background-color: #ffffff;

    input {
      margin: 10px 0;
      padding: 8px;
      border-radius: 4px;
      border: 1px solid #ccc;
      width: 100%;
    }

    button {
      padding: 10px;
      margin-top: 10px;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      width: 100%;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}

.file-upload {
  margin-top: 20px;
}

#file-input + label {
  cursor: pointer;
}

.text-danger {
  color: red;
  font-size: 0.9em;
}

.webcam-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;

  .responsive-webcam {
    width: 100%; 
    max-width: 320px;
    height: auto;
  }
}

.image-border{
  border-radius: 2px;
  border: 2px solid #add8e6;
}

.responsive-width-30 {
  width: 30%;
  max-width: 320px;

  @media (max-width: 320px) {
    width: 80%;
  }

  @media (min-width: 321px) and (max-width: 576px) {
    width: 75%;
  }

  @media (min-width: 577px) and (max-width: 768px) {
    width: 65%;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    width: 50%;
  }

  @media (min-width: 1025px) {
    width: 40%;
  }
}

@media (max-width: 320px) {
  .login img {
    width: 80%;
    max-width: 180px;
  }

  .login form {
    width: 95%;
  }
}

@media (min-width: 321px) and (max-width: 576px) {
  .login img {
    width: 75%;
    max-width: 200px;
  }

  .login form {
    width: 90%;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .login img {
    width: 65%;
    max-width: 250px;
  }

  .login form {
    width: 80%;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .login img {
    width: 50%;
    max-width: 300px;
  }

  .login form {
    width: 70%;
  }
}

@media (min-width: 1025px) {
  .login img {
    width: 40%;
    max-width: 350px;
  }

  .login form {
    width: 500px;
    
  }
}
